<script lang="ts" setup>
import type { TypeCrtCompany } from "~/types/csv/TypeCrt";
import { sortByKey } from "~/lib/sort";
import { updateFiltersChecked, filterArray } from "~/lib/filter";
import type { TypeChartDataLayer } from "~/types/TypeChartDataLayer";
import { pushDataLayerEvent } from "~/lib/client-data-layer";
import type { TypeChartCompany } from "~/types/TypeChartCompany";
import type { TypeProject } from "~/types/contentful";

const props = defineProps<{
  project: TypeProject<"WITHOUT_UNRESOLVABLE_LINKS", "en-GB">;
  filterPanelBlocks: string[][];
  filterFields: string[];
  filterTitles: string[];
  companies: TypeCrtCompany[];
  regions: string[];
  proteinTypes: string[];
}>();

const xValues = ["Companies", "Protein Types", "Regions"];
const yValues = ["Costs", "EBIT Margin"];
const scenarios = [
  "All Scenarios",
  "Net Zero Aligned",
  "BAU",
  "High Climate Impact",
];
const yFields = ["Costs", "EBIT"];
const yRangeFields = ["Costs Change", "EBIT Change"];
const yKeys = ["Net Zero Aligned", "BAU", "High Climate Impact"];
const yColours = ["#63c954", "#ff962a", "#ff3736"];

const xSelected = ref<string>(xValues[0] ?? "");
const ySelected = ref<string>(yValues[0] ?? "");
const ySelectedIndex = computed(() => yValues.indexOf(ySelected.value));
const ySelectedField = computed(() => yFields[ySelectedIndex.value] ?? "");

const yearSelected = ref<string>("2030");

const filtersChecked = ref<{ field: string; id: string }[]>([]);

const scenarioSortKeys: (keyof TypeChartCompany)[] = [
  "valueChange",
  "valueLow",
  "valueBAU",
  "valueHigh",
];
const scenarioSelected = ref<string>(scenarios[0] ?? "");
const scenarioIndex = computed(() => scenarios.indexOf(scenarioSelected.value));
const scenarioSortKey = computed(() => scenarioSortKeys[scenarioIndex.value] ?? "");

const isDoubleChart = computed(() => xSelected.value !== "Companies");
const orderedBy = computed(() => {
  if (scenarioSelected.value === "All Scenarios") return "Range Size";
  if (ySelectedField.value === "EBIT") return "EBIT Margin";
  return "Cost";
});
const yRangeField = computed(() => {
  const field = yRangeFields[ySelectedIndex.value] ?? "";
  return `${field} ${yearSelected.value}`;
});

const chartCompanies = computed(() => {
  const filteredCompanies = filterArray(props.companies, filtersChecked.value);

  const array: TypeChartCompany[] = filteredCompanies.map(
    (row: TypeCrtCompany) => {
      const c = {
        csv: row,
        name: row["Company Abbreviation"],
        valueHigh: parseInt(
          row[
            `${ySelectedField.value} ${yearSelected.value} High` as keyof TypeCrtCompany
          ],
        ),
        valueBAU: parseInt(
          row[
            `${ySelectedField.value} ${yearSelected.value} BAU` as keyof TypeCrtCompany
          ],
        ),
        valueLow: parseInt(
          row[
            `${ySelectedField.value} ${yearSelected.value} Low` as keyof TypeCrtCompany
          ],
        ),
        valueChange: Math.abs(
          parseInt(row[yRangeField.value as keyof TypeCrtCompany]),
        ),
      };

      return { ...row, ...c };
    },
  );

  return scenarioSortKey.value ? sortByKey(array, scenarioSortKey.value).reverse() : array;
});

const regionValues2030 = computed(() =>
  averageItems(props.regions, "Region", "2030"),
);
const regionValues2050 = computed(() =>
  averageItems(props.regions, "Region", "2050"),
);
const proteinValues2030 = computed(() =>
  averageItems(props.proteinTypes, "Main Protein Category", "2030"),
);
const proteinValues2050 = computed(() =>
  averageItems(props.proteinTypes, "Main Protein Category", "2050"),
);

const averageItems = (
  items: string[],
  field: keyof TypeCrtCompany,
  year: string,
) => {
  // items: list of proteins or regions ['Africa', 'Asia']
  const array = items.map((name: string) => {
    const filteredCompanies = props.companies.filter((c) => c[field] === name);
    return averageItem(filteredCompanies, name, ySelectedField.value, year);
  });
  return scenarioSortKey.value ? sortByKey(array, scenarioSortKey.value).reverse() : array;
};

const averageItem = (
  items: TypeCrtCompany[],
  name: string,
  field: string,
  year: string,
): TypeChartCompany => {
  return {
    csv: items[0],
    name,
    valueHigh: getAverage(
      items,
      `${field} ${year} High` as keyof TypeCrtCompany,
    ),
    valueBAU: getAverage(items, `${field} ${year} BAU` as keyof TypeCrtCompany),
    valueLow: getAverage(items, `${field} ${year} Low` as keyof TypeCrtCompany),
    valueChange: Math.abs(
      getAverage(
        items,
        `${yRangeFields[ySelectedIndex.value] ?? ""} ${year}` as keyof TypeCrtCompany,
      ),
    ),
  };
};

const getAverage = (
  array: TypeCrtCompany[],
  property: keyof TypeCrtCompany,
) => {
  return (
    array.reduce(
      (total: number, next: TypeCrtCompany) => total + parseInt(next[property]),
      0,
    ) / array.length
  );
};

const chartItems = computed(() => {
  if (xSelected.value === xValues[0]) return chartCompanies.value;
  if (xSelected.value === xValues[1]) return proteinValues2030.value;
  if (xSelected.value === xValues[2]) return regionValues2030.value;
  return chartCompanies.value;
});

const chart2Items = computed(() => {
  if (xSelected.value === xValues[1]) return proteinValues2050.value;
  if (xSelected.value === xValues[2]) return regionValues2050.value;
  return chartCompanies.value;
});

const onFilterChange = (checked: boolean, id: string, index: number) => {
  const field = props.filterFields[index] ?? "";

  updateFiltersChecked(filtersChecked.value, field, checked, id);

  if (checked) {
    chartDataLayer.filter = {
      group: field,
      value: id,
    };
    pushDataLayerEvent("evChartFilter", chartDataLayer);
  }
};

const onClearClick = () => {
  filtersChecked.value = [];
};

const chartDataLayer = inject<TypeChartDataLayer>(
  "chartDataLayer",
  {} as TypeChartDataLayer,
);

watch(orderedBy, (value) => (chartDataLayer.orderBy = value));

const showPillars = ref(false);
</script>

<template>
  <Container class="tool-chart">
    <ChartControls
      :filter-panel-blocks="filterPanelBlocks"
      :filter-titles="filterTitles"
      :x-values="xValues"
      :y-values="yValues"
      :scenarios="scenarios"
      :disable-years="isDoubleChart"
      :year="yearSelected"
      :scenario="scenarioSelected"
      :x-selected="xSelected"
      :y-selected="ySelected"
      :is-double-chart="isDoubleChart"
      :control-pillars="false"
      @on-x-select="
        xSelected = $event;
        chartDataLayer.xValue = $event;
        pushDataLayerEvent('evChartXSelected', chartDataLayer);
      "
      @on-y-select="
        ySelected = $event;
        chartDataLayer.yValue = $event;
        pushDataLayerEvent('evChartYSelected', chartDataLayer);
      "
      @on-filter-change="onFilterChange"
      @on-clear-click="onClearClick"
      @on-scenario="
        scenarioSelected = $event;
        chartDataLayer.scenario = $event;
        pushDataLayerEvent('evChartScenarioSelected', chartDataLayer);
      "
      @on-pillars="showPillars = $event"
      @on-year="
        yearSelected = $event;
        chartDataLayer.year = $event;
        pushDataLayerEvent('evChartYearSelected', chartDataLayer);
      "
      @on-title-update="chartDataLayer.title = $event"
    />

    <div class="ms-2 flex ps-4 md:ms-1 lg:ps-0 2xl:ms-0">
      <Chart
        class="mb-5"
        :project="project"
        :items="chartItems"
        :title-left="
          ySelected
            + (ySelected === 'EBIT Margin' ? '' : ' (% Change from 2020)')
        "
        :value-high-red="ySelected == yValues[0]"
        :min-score="ySelected == yValues[0] ? -20 : -60"
        :max-score="ySelected == yValues[0] ? 80 : 60"
        :scenario="scenarioSelected"
        :ordered-by="isDoubleChart ? '' : orderedBy"
        :is-double-chart="isDoubleChart"
        :year="isDoubleChart ? '2030' : ''"
        :year-selected="yearSelected"
      />
      <Chart
        v-if="isDoubleChart && chart2Items"
        class="mb-5"
        :project="project"
        :items="chart2Items"
        title-left=""
        :value-high-red="ySelected == yValues[0]"
        :min-score="ySelected == yValues[0] ? -20 : -60"
        :max-score="ySelected == yValues[0] ? 80 : 60"
        :scenario="scenarioSelected"
        :ordered-by="orderedBy"
        :year-selected="yearSelected"
        :is-double-chart="isDoubleChart"
        year="2050"
      />
    </div>

    <div class="tool-chart__keys mx-auto flex items-center px-2 text-xs">
      <div
        v-for="(key, k) in yKeys"
        :key="k"
        class="p-2 font-medium tracking-widest"
      >
        <span>{{ key }}</span>
        <span
          class="key-color ms-2"
          :style="{ background: yColours[k] }"
        />
      </div>
    </div>
  </Container>
</template>
